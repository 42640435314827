export const tableFields = [
  { key: 'name', i18nKey: 'distributions.label.table.name', tdClass: 'align-middle' },
  { key: 'date', i18nKey: 'distributions.label.table.date', tdClass: 'align-middle' },
  {
    key: 'secondaryControls',
    cta: {
      edit: 'distributions.button.snapshot.edit',
      view: 'distributions.button.snapshot.view',
      download: 'distributions.button.csv.download',
    },
    label: '',
    tdClass: 'align-middle',
  },
];
export const innerTableFields = [
  { key: 'name', i18nKey: 'distributionDetail.label.table.name', tdClass: 'align-middle' },
  { key: 'email', i18nKey: 'distributionDetail.label.table.email', tdClass: 'align-middle' },
  { key: 'tokens', i18nKey: 'distributionDetail.label.table.tokens', sortable: true },
  { key: 'wallet', i18nKey: 'distributionDetail.label.table.wallet', tdClass: 'align-middle' },
  { key: 'type', i18nKey: 'distributionDetail.label.table.type', tdClass: 'align-middle' },
  { key: 'payoutWallet', i18nKey: 'distributionDetail.label.table.payoutWallet', tdClass: 'align-middle' },
];
