<template>
  <cp-filter
    ref="cpFilter"
    name="snapshot"
    @input="updateFilter"
  >
    <div class="form-row align-items-center">
      <div class="col-md-6 filterBlock">
        <cp-date-range
          v-validate="'date_range'"
          name="from-created-at"
          :label="$t('snapshots.label.filter.dateRange')"
        />
      </div>
    </div>
  </cp-filter>
</template>

<script>
import CpDateRange from '~/components/common/standalone-components/inputs/cp-date-range';
import CpFilter from '~/components/common/cp-filter';
import filterParseForApi from '~/utilities/investorFilterParseForApi';

export default {
  name: 'SnapshotFilter',
  components: {
    CpDateRange,
    CpFilter,
  },
  methods: {
    updateFilter() {
      this.$emit('updateFilter', this.getActiveFilters());
    },
    getActiveFilters() {
      return filterParseForApi(this.$refs.cpFilter.getDefaultValue);
    },
  },
};
</script>

<style lang="scss">
.filterBlock {
  margin: 0 2%;
  min-width: 25%;
}
</style>
