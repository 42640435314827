<template>
  <cp-general-modal
    ref="snapshotModal"
    :title="$t('distributions.snapshotModal.title.common', [titleData.label])"
    :title-icon="titleData.icon"
    @onOk="onSubmit"
  >
    <cp-input
      v-model="model.name"
      :label="$t('distributions.snapshotModal.input.name.text')"
      name="name"
    />
    <div
      v-if="titleData.type !== 'edit'"
      class="form-group"
    >
      <label class="form-label">
        {{ $t('distributions.snapshotModal.input.dateTime') }}
      </label>
      <div class="form-control-plaintext">
        {{ model.date | dateAndTimeFilter }}
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'CpSnapshotModal',

  components: {
    CpGeneralModal,
    CpInput,
  },

  props: {
    snapshot: {
      type: Object,
      default: () => ({}),
    },
    titleData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      model: {},
    };
  },

  watch: {
    snapshot(value) {
      this.model = Object.assign({}, value);
    },
  },

  methods: {
    onSubmit() {
      const action = this.titleData.type === 'edit' ? 'patch' : 'post';
      this.$emit('onSubmit', { action, snapshot: this.model });
    },
  },
};
</script>
